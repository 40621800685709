

import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
	<Layout>


<div className="inner-top-desc">
	<h3 className="text-sm py-6 font-bold uppercase text-gray-600">Why SignTech?</h3>
	
	<div className="grid gap-x-8 gap-y-5 grid-cols-3">
		<div>
			<h3 className="text-sm py-4 font-extrabold text-gray-800">Truly Personalized Service</h3>
			<p className="text-sm">From the minute you walk-in through our door, or from the moment you call us or send us an email, Sign Tech offers a truly personalized and professional service. Need something exclusive or "off the menu"? No problem! We will help you get what you want.</p>
		</div>
		<div>
			<h3 className="text-sm py-4 font-extrabold text-gray-800">No Time Wasting</h3>
			<p className="text-sm">We at Sign Tech fully believe and practice the age old proverb that 'time means money' and ensure that the time you deal with us is well spent. We have also made it possible for our customers to speak directly to the people handling your job. </p>
		</div>
		<div>
			<h3 className="text-sm py-4 font-extrabold text-gray-800">Accreditations/ Recognized Certifications</h3>
			<p className="text-sm">Our international dealerships and accreditations is in itself a testimony to the quality of our service. Sign Tech is the 'Authorized Sole 3M sign converter for 2012 from USA' and is also a Distributor of MagicTouch products from Dubai.</p>
		</div>

		<div>
			<h3 className="text-sm py-4 font-extrabold text-gray-800">Technological Innovations</h3>
			<p className="text-sm">Sign Tech's dedicated and experienced team along with carefully selected productivity options provides customers with the best products, customer service and technical support available in the advertising industry.</p>
		</div>
		<div>
			<h3 className="text-sm py-4 font-extrabold text-gray-800">Our People</h3>
			<p className="text-sm">Our greatest asset and the secret of our continued success is our people. Our team of professionals possess a unique blend of experience, creativity and businessacumen and includes some of the most respected and well-known individuals in the industry.</p>
		</div>
		<div>
			<h3 className="text-sm py-4 font-extrabold text-gray-800">Supplier Chain</h3>
			<p className="text-sm">Our Supplier chain includes reliable suppliers with whom we have been dealing for many years now. This includes market leaders of unparalleled global repute as well as many local industry giants.</p>
		</div>
	</div>
</div>

<div className="inner-top-desc">
	
	<h3 className="text-sm py-6 font-bold uppercase text-gray-600">
		The Management Team
	</h3>

	<div className="grid grid-cols-2 gap-x-5">
		<div className="col-md-6 col-sm-12 col-xs-12">
			
			<div className="mteam-box p-5 mb-5 bg-gray-300">
				<img className="float-left mr-5 mb-5" src="images/about/mr-kassapa.jpg"/>
				<h3 className="font-bold text-gray-600">Mr. Kassapa Mahendra De Silva</h3>
				<h4 className="text-xs pb-3 uppercase">Chairman / Managing Director</h4>
				<p className="text-sm pb-3">One of the most respected and well known personalities in the industry, Mr. De Silva is the Founder and Owner of the Company and his dynamic leadership has enabled Sign Tech to grow from 2 employees to its current position.</p>
				<p className="text-sm">An active social worker with the Lions movement, Mr. De Silva also served as the District Governor for the International Association of Lions Clubs District 306 C for the year 2004/05, a position to which he was elected within a very short period after joining as a Lions Member. During that year he was selected as the 1st runner-up of the 'Best District Governor of the World'award at the International Lions Convention 2004-2005. He was also the President of the Senior Old Boy's Association of Nalanda College in 2009/2010.</p>
			</div>
			<div className="mteam-box p-5 mb-5 bg-gray-300">
				<img className="float-left mr-5 mb-5" src="images/about/mrs-upulkanthi.jpg"/>
				<h3 className="font-bold text-gray-600">Mrs. UpulKanthi De Silva</h3>
				<h4 className="text-xs pb-3 uppercase">Director</h4>
				<p className="text-sm">The Wife of Mr. KassapaMahendra De Silva, Mrs. De Silva has been with Sign Tech from its inception and has been an absolute pillar of strength. She currently manages the production facility and all production work of the Company.</p>
			</div>

		</div>
		<div className="col-md-6 col-sm-12 col-xs-12">
			<div className="mteam-box p-5 mb-5 bg-gray-300">
			
				<img className="float-left mr-5 mb-5" src="images/about/avatar.jpg"/>
				<h3 className="font-bold text-gray-600">Ms. Dileka De Silva</h3>
				<h4 className="text-xs pb-3 uppercase">Chief Executive Officer</h4>
				<p className="text-sm">Overlooking the operations of Sign Tech for the past 3 years, Ms. De Silva holds a 1st className Honors Degree in Business Information Technology from the Staffordshire University UK. An active social and youth worker, Ms. De Silva was the District President of Leo District 306 C1 for the year 2011/12.</p>
			</div>
			<div className="mteam-box p-5 mb-5 bg-gray-300">
				<img className="float-left mr-5 mb-5" src="images/about/avatar.jpg"/>
				<h3 className="font-bold text-gray-600">Mrs. Nimalka Piyadasa</h3>
				<h4 className="text-xs pb-3 uppercase">General Manager</h4>
				<p className="text-sm">A graduate from the University of Peradeniya Mrs. Piyadasa is a qualified Chartered Accountant and started at Sign Tech as the Finance Manager. She was later promoted to the position of General Manager.</p>
			</div>

		</div>
		
	</div>
	
</div>

<div className="inner-top-desc">
				
	<h3 className="text-sm py-6 font-bold uppercase text-gray-600">
				Csr Activities
	</h3>
	
			
			<p className="text-sm">At Sign Tech, we believe that CSR is all about demonstrating our corporate values beyond the confines of the Company. We believe in being responsible corporate citizens and making significant contributions to the communities we belong to.
			 Some of our main CSR Projects include</p>

	<div className="grid grid-cols-3 gap-x-5">
		<div className="py-5">
			<img className="float-left mr-5" src="images/about/csr-01.jpg"/>
			<p className="text-xs">Sign Tech sponsored and built the Nalanda College Squash Court at a valued at over Rs. 50 million and donated it to the school.</p>
		</div>

		<div className="py-5">
			<img className="float-left mr-5" src="images/about/csr-02.jpg"/>
			<p className="text-xs">Sign Tech the salaries of several staff members of the Dombagoda Maha Vidyalaya on a monthly basis. </p>
		</div>
		
		<div className="py-5">
			<img className="float-left mr-5" src="images/about/csr-03.jpg"/>
			<p className="text-xs">Providing a monthly donation towards the up-keeping of a temple in Sinharaja.</p>
		</div>
	</div>
	
</div>



	</Layout>
)

export default AboutPage